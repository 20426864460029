import { Component, OnInit, ViewChild, NgZone } from '@angular/core';

import { Platform, IonMenu } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { GlobalService } from "./global.service";
import { AlertController, NavController } from '@ionic/angular';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public menu_full:boolean = true;
  public selectedIndex: number = 0;
  public appPages = [
    {
      title: 'Appointment',
      url: '/appointment-list',
      icon: 'reader'
    },
    {
      title: 'Dashboard',
      url: '/overall',
      icon: 'desktop'
    },
    {
      title: 'Home Page',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Product',
      url: '/products',
      icon: 'shirt'
    },
    {
      title: 'Members',
      url: '/members',
      icon: 'people'
    },
    {
      // title: 'Order',
      // url: '/order-list',
      // icon: 'document-text'
      title: 'Order',
      // url: '/settings',
      icon: 'document-text',
      subpage: [
        {
          title: 'Order',
          url: '/order-list'
        },
        {
          title: 'Plan Orders',
          url: '/plan-list'
        },
        {
          title: 'Rental Status',
          url: '/rental-list'
        },
        {
          title: 'POS',
          url: '/pos-list'
        },
        {
          title: 'Laundry',
          url: '/laundry-list'
        }
      ]
    },
    {
      title: 'Coupon & Discount',
      url: '/coupon-list',
      icon: 'pricetags'
    // },
    // {
    //   title: 'Blog',
    //   url: '/blog',
    //   icon: 'browsers'
    },
    {
      title: 'Staff',
      url: '/staff',
      icon: 'walk'
    },
    {
      title: 'Settings',
      // url: '/settings',
      icon: 'settings',
      subpage: [
        {
          title: 'Categories',
          url: '/catergory-list',
          // icon: 'people'
        },
        {
          title: 'Designers',
          url: '/designer-list',
          // icon: 'people'
        },
        {
          title: 'Brands',
          url: '/brand-list',
          // icon: 'people'
        },
        {
          title: 'Length',
          url: '/length-list',
          // icon: 'people'
        },
        {
          title: 'Fitting',
          url: '/fittin-list',
          // icon: 'people'
        },
        {
          title: 'Shop Setting',
          url: '/shop-setting',
          // icon: 'people'
        },
        {
          title: 'Photo Package',
          url: '/photo-package-setting',
          // icon: 'people'
        },
        {
          title: 'Appointment',
          url: '/appointment-setting',
          // icon: 'people'
        }
      ]
    }
  ];
  public current_url: string = "";
  public subSelectedIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public subOpen = [true, false, false, false, false, false, false, false, false, false];
  public labels = [];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public http: HTTP,
    private nativeStorage: NativeStorage,
    private alertCtl: AlertController,
    public global_service: GlobalService,
    private router: Router,
    public navCtrl: NavController,
    private location: Location,
    public zone: NgZone
  ) {
    this.router.events.subscribe(val => { if (location.path() != "") { this.current_url = this.router.url; this.selectedIndex = this.appPages.findIndex(d => d.url == location.path()); } });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', function (event) {
          event.preventDefault();
          event.stopPropagation();
          console.log('hello');
        }, false);
      });
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
    if (this.global_service.login_data.user_type == 'staff') {
      this.subSelectedIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.subOpen = [true, false, false, false, false, false, false, false, false];
    }
    else {
      this.subSelectedIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.subOpen = [true, false, false, false, false, false, false, false, false, false];
    }
    this.subOpen[0] = true;
  }
  ionViewDidEnter() {
    this.reloadPages();
  }
  reloadPages(){
    console.log("reloadPage");
    if(this.global_service.login_data != undefined && this.global_service.login_data != null){
      if(this.global_service.login_data.user_type == "developer"){
        this.appPages = this.global_service.developer_appPages;
      }
      if(this.global_service.login_data.user_type == "admin"){
        this.appPages = this.global_service.admin_appPages;
      }
      if(this.global_service.login_data.user_type == "staff"){
        this.appPages = this.global_service.staff_appPages;
      }
    }
    this.router.events.subscribe(val => { if (this.location.path() != "") { this.current_url = this.router.url; this.selectedIndex = this.appPages.findIndex(d => d.url == this.location.path()); } });
  }

  resetOpen(i) {
    if (this.global_service.login_data.user_type == 'staff') {
      this.subOpen = [false, false, false, false, false, false, false, false, false];
    }
    else {
      this.subOpen = [false, false, false, false, false, false, false, false, false, false];
    }
    this.subOpen[i] = true;
  }

  resetOpenBut(i) {
    if (this.global_service.login_data.user_type == 'staff') {
      this.subOpen = [false, false, false, false, false, false, false, false, false];
    }
    else {
      this.subOpen = [false, false, false, false, false, false, false, false, false, false];
    }
    this.subOpen[i] = true;
  }

  setSelectedIndex(i, url) {
    this.zone.run(() => {
      this.selectedIndex = i;
      this.resetOpen(i);
      this.navCtrl.navigateForward(url);
    });

  }

  switchMode(){
    this.menu_full = !this.menu_full;
  }

  setParentSelectedIndex(i) {
    this.zone.run(() => {
      this.selectedIndex = i;
      this.resetOpenBut(i);
      this.navCtrl.navigateForward(this.appPages[this.selectedIndex].subpage[this.subSelectedIndex[this.selectedIndex]].url);
    });
  }

  logout() {
    this.global_service.login_data = null;
    this.nativeStorage.setItem('staff', null)
      .then(
        () => {
          this.navCtrl.navigateForward("/login");
        },
        error => {
          console.error('Error storing item', error);
          this.navCtrl.navigateForward("/login");
        }
      );
  }
}
