import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public user_data: any = {};
  public category_data: any = {};
  public design_data: any = {};
  public brand_data: any = {};
  public length_data: any = {};
  public fittin_data: any = {};
  public product_data: any = {};
  public client_data: any = {};
  public appointment_data: any = {};
  public order_data: any = {};
  public vip_data: any = {};
  public coupon_data: any = null;
  public promo_data: any = {};
  public plan_data: any = {};
  public staff_data: any = {};
  public selected_sku: any = [];
  public selected_user: any = [];
  public login_data: any = null;
  public blog_data: any = null;
  public search_product_data: any = null;
  public search_client_data: any = null;
  public contact_us_data: any = null;
  public photo_package_data: any = null;

  public credit_card: any = null;

  public editing_product: any = null;
  public editing_order: any = null;
  public search_product_type: string = "";
  public charge_data: any = {};

  public product_can_edit: boolean = false;
  public user_can_edit: boolean = false;
  public order_can_edit: boolean = false;
  public product_can_edit_count: number = 0;
  public user_can_edit_count: number = 0;
  public order_can_edit_count: number = 0;

  public staff_appPages = [
    {
      title: 'Appointment',
      url: '/appointment-list',
      icon: 'reader'
    },
    {
      title: 'Dashboard',
      url: '/overall',
      icon: 'desktop'
    },
    {
      title: 'Home Page',
      url: '/home',
      icon: 'home'
    },
    // {
    //   title: 'Gallery',
    //   url: '/gallery',
    //   icon: 'albums'
    // },
    {
      title: 'Product',
      url: '/products',
      icon: 'shirt'
    },
    {
      title: 'Members',
      url: '/members',
      icon: 'people'
    },
    {
      // title: 'Order',
      // url: '/order-list',
      // icon: 'document-text'
      title: 'Order',
      // url: '/settings',
      icon: 'document-text',
      subpage: [
        {
          title: 'Order',
          url: '/order-list'
        },
        {
          title: 'Photo Package',
          url: '/photopackage-list'
        },
        {
          title: 'Plan Orders',
          url: '/plan-list'
        },
        {
          title: 'Rental Status',
          url: '/rental-list'
        },
        {
          title: 'POS',
          url: '/pos-list'
        },
        {
          title: 'Laundry',
          url: '/laundry-list'
        },
        {
          title: 'Previous Order',
          url: '/previous-order'
        },
        {
          title: 'Penalty Charge',
          url: '/charge-list'
        }
      ]
    },
    {
      title: 'Coupon & Discount',
      url: '/coupon-list',
      icon: 'pricetags'
    },
    // {
    //   title: 'Blog',
    //   url: '/blogs',
    //   icon: 'browsers'
    // },
    // {
    //   title: 'Staff',
    //   url: '/staff',
    //   icon: 'walk'
    // },
    {
      title: 'Contact Us',
      url: '/contact-us-list',
      icon: 'call'
    },
    {
      title: 'Subscribe',
      url: '/subscribs',
      icon: 'newspaper'
    },
    {
      title: 'Settings',
      // url: '/settings',
      icon: 'settings',
      subpage: [
        // {
        //   title: 'Categories',
        //   url: '/catergory-list',
        //   // icon: 'people'
        // },
        {
          title: 'Designers',
          url: '/designer-list',
          // icon: 'people'
        },
        {
          title: 'Brands',
          url: '/brand-list',
          // icon: 'people'
        },
        {
          title: 'Length',
          url: '/length-list',
          // icon: 'people'
        },
        {
          title: 'Fitting',
          url: '/fittin-list',
          // icon: 'people'
        },
        {
          title: 'Shop Setting',
          url: '/shop-setting',
          // icon: 'people'
        },
        {
          title: 'Photo Package',
          url: '/photo-package-setting',
          // icon: 'people'
        },
        {
          title: 'Appointment',
          url: '/appointment-setting',
          // icon: 'people'
        }
      ]
    }
  ];
  
  public admin_appPages = [
    {
      title: 'Appointment',
      url: '/appointment-list',
      icon: 'reader'
    },
    {
      title: 'Dashboard',
      url: '/overall',
      icon: 'desktop'
    },
    {
      title: 'Home Page',
      url: '/home',
      icon: 'home'
    },
    // {
    //   title: 'Gallery',
    //   url: '/gallery',
    //   icon: 'albums'
    // },
    {
      title: 'Product',
      url: '/products',
      icon: 'shirt'
    },
    {
      title: 'Members',
      url: '/members',
      icon: 'people'
    },
    {
      // title: 'Order',
      // url: '/order-list',
      // icon: 'document-text'
      title: 'Order',
      // url: '/settings',
      icon: 'document-text',
      subpage: [
        {
          title: 'Order',
          url: '/order-list'
        },
        {
          title: 'Photo Package',
          url: '/photopackage-list'
        },
        {
          title: 'Plan Orders',
          url: '/plan-list'
        },
        {
          title: 'Rental Status',
          url: '/rental-list'
        },
        {
          title: 'POS',
          url: '/pos-list'
        },
        {
          title: 'Laundry',
          url: '/laundry-list'
        },
        {
          title: 'Previous Order',
          url: '/previous-order'
        },
        {
          title: 'Penalty Charge',
          url: '/charge-list'
        }
      ]
    },
    {
      title: 'Coupon & Discount',
      url: '/coupon-list',
      icon: 'pricetags'
    },
    // {
    //   title: 'Blog',
    //   url: '/blogs',
    //   icon: 'browsers'
    // },
    {
      title: 'Staff',
      url: '/staff',
      icon: 'walk'
    },
    {
      title: 'Contact Us',
      url: '/contact-us-list',
      icon: 'call'
    },
    {
      title: 'Subscribe',
      url: '/subscribs',
      icon: 'newspaper'
    },
    {
      title: 'Settings',
      // url: '/settings',
      icon: 'settings',
      subpage: [
        // {
        //   title: 'Categories',
        //   url: '/catergory-list',
        //   // icon: 'people'
        // },
        {
          title: 'Designers',
          url: '/designer-list',
          // icon: 'people'
        },
        {
          title: 'Brands',
          url: '/brand-list',
          // icon: 'people'
        },
        {
          title: 'Length',
          url: '/length-list',
          // icon: 'people'
        },
        {
          title: 'Fitting',
          url: '/fittin-list',
          // icon: 'people'
        },
        {
          title: 'Shop Setting',
          url: '/shop-setting',
          // icon: 'people'
        },
        {
          title: 'Photo Package',
          url: '/photo-package-setting',
          // icon: 'people'
        },
        {
          title: 'Appointment',
          url: '/appointment-setting',
          // icon: 'people'
        }
      ]
    }
  ];

  public developer_appPages = [
    {
      title: 'Appointment',
      url: '/appointment-list',
      icon: 'reader'
    },
    {
      title: 'Dashboard',
      url: '/overall',
      icon: 'desktop'
    },
    {
      title: 'Home Page',
      url: '/home',
      icon: 'home'
    },
    // {
    //   title: 'Gallery',
    //   url: '/gallery',
    //   icon: 'albums'
    // },
    {
      title: 'Product',
      url: '/products',
      icon: 'shirt'
    },
    {
      title: 'Members',
      url: '/members',
      icon: 'people'
    },
    {
      // title: 'Order',
      // url: '/order-list',
      // icon: 'document-text'
      title: 'Order',
      // url: '/settings',
      icon: 'document-text',
      subpage: [
        {
          title: 'Order',
          url: '/order-list'
        },
        {
          title: 'Photo Package',
          url: '/photopackage-list'
        },
        {
          title: 'Plan Orders',
          url: '/plan-list'
        },
        {
          title: 'Rental Status',
          url: '/rental-list'
        },
        {
          title: 'POS',
          url: '/pos-list'
        },
        {
          title: 'Laundry',
          url: '/laundry-list'
        },
        {
          title: 'Previous Order',
          url: '/previous-order'
        },
        {
          title: 'Penalty Charge',
          url: '/charge-list'
        }
      ]
    },
    {
      title: 'Coupon & Discount',
      url: '/coupon-list',
      icon: 'pricetags'
    },
    // {
    //   title: 'Blog',
    //   url: '/blogs',
    //   icon: 'browsers'
    // },
    {
      title: 'Staff',
      url: '/staff',
      icon: 'walk'
    },
    {
      title: 'Contact Us',
      url: '/contact-us-list',
      icon: 'call'
    },
    {
      title: 'Subscribe',
      url: '/subscribs',
      icon: 'newspaper'
    },
    {
      title: 'Settings',
      // url: '/settings',
      icon: 'settings',
      subpage: [
        {
          title: 'Categories',
          url: '/catergory-list',
          // icon: 'people'
        },
        {
          title: 'Designers',
          url: '/designer-list',
          // icon: 'people'
        },
        {
          title: 'Brands',
          url: '/brand-list',
          // icon: 'people'
        },
        {
          title: 'Length',
          url: '/length-list',
          // icon: 'people'
        },
        {
          title: 'Fitting',
          url: '/fittin-list',
          // icon: 'people'
        },
        {
          title: 'Shop Setting',
          url: '/shop-setting',
          // icon: 'people'
        },
        {
          title: 'Photo Package',
          url: '/photo-package-setting',
          // icon: 'people'
        },
        {
          title: 'Appointment',
          url: '/appointment-setting',
          // icon: 'people'
        }
      ]
    }
  ];

  constructor(
    public loadingController: LoadingController
  ) { }

  firstFileToBase64(fileImage: File): Promise<{}> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  public load_count: number = 0;
  async presentLoading(time?) {
    if(this.load_count==0){
      this.load_count++;
      return await this.loadingController.create({
        message: '請稍候。。。',
        mode: "ios",
        spinner: 'crescent',
        cssClass: 'LoadingCss',
        duration: (time != undefined ? time : 20000),
      }).then(a => {
        a.present().then(() => {
          if (this.load_count <= 0) {
            this.load_count = 0;
            a.dismiss();
          }
        });
      });
    }else{
      this.load_count++;
    }
  }

  async dismissLoading(time?) {
    this.load_count--;
    if (this.load_count <= 0) {
      this.load_count = 0;
      return await this.loadingController.dismiss();
    }
  }

  formatDate(date, format, utc) {
    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    function ii(i, len) {
        var s = i + "";
        len = len || 2;
        while (s.length < len) s = "0" + s;
        return s;
    }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M,2));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d,2));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H,2));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h,2));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m,2));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s,2));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f,2));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs,2) + ":" + ii(tzMin,2);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
};
}
