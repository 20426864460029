import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: 'members',
    loadChildren: () => import('./members/members.module').then( m => m.MembersPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'member-detail',
    loadChildren: () => import('./member-detail/member-detail.module').then( m => m.MemberDetailPageModule)
  },
  {
    path: 'catergory-list',
    loadChildren: () => import('./catergory-list/catergory-list.module').then( m => m.CatergoryListPageModule)
  },
  {
    path: 'designer-list',
    loadChildren: () => import('./designer-list/designer-list.module').then( m => m.DesignerListPageModule)
  },
  {
    path: 'brand-list',
    loadChildren: () => import('./brand-list/brand-list.module').then( m => m.BrandListPageModule)
  },
  {
    path: 'catergory-detail',
    loadChildren: () => import('./catergory-detail/catergory-detail.module').then( m => m.CatergoryDetailPageModule)
  },
  {
    path: 'designer-detail',
    loadChildren: () => import('./designer-detail/designer-detail.module').then( m => m.DesignerDetailPageModule)
  },
  {
    path: 'brand-detail',
    loadChildren: () => import('./brand-detail/brand-detail.module').then( m => m.BrandDetailPageModule)
  },
  {
    path: 'shop-setting',
    loadChildren: () => import('./shop-setting/shop-setting.module').then( m => m.ShopSettingPageModule)
  },
  {
    path: 'appointment-list',
    loadChildren: () => import('./appointment-list/appointment-list.module').then( m => m.AppointmentListPageModule)
  },
  {
    path: 'appointment-detail',
    loadChildren: () => import('./appointment-detail/appointment-detail.module').then( m => m.AppointmentDetailPageModule)
  },
  {
    path: 'order-list',
    loadChildren: () => import('./order-list/order-list.module').then( m => m.OrderListPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'vip-list',
    loadChildren: () => import('./vip-list/vip-list.module').then( m => m.VipListPageModule)
  },
  {
    path: 'vip-detail',
    loadChildren: () => import('./vip-detail/vip-detail.module').then( m => m.VipDetailPageModule)
  },
  {
    path: 'coupon-list',
    loadChildren: () => import('./coupon-list/coupon-list.module').then( m => m.CouponListPageModule)
  },
  {
    path: 'coupon-detail',
    loadChildren: () => import('./coupon-detail/coupon-detail.module').then( m => m.CouponDetailPageModule)
  },
  {
    path: 'promo-detail',
    loadChildren: () => import('./promo-detail/promo-detail.module').then( m => m.PromoDetailPageModule)
  },
  {
    path: 'plan-list',
    loadChildren: () => import('./plan-list/plan-list.module').then( m => m.PlanListPageModule)
  },
  {
    path: 'plan-detail',
    loadChildren: () => import('./plan-detail/plan-detail.module').then( m => m.PlanDetailPageModule)
  },
  {
    path: 'rental-list',
    loadChildren: () => import('./rental-list/rental-list.module').then( m => m.RentalListPageModule)
  },
  {
    path: 'rental-detail',
    loadChildren: () => import('./rental-detail/rental-detail.module').then( m => m.RentalDetailPageModule)
  },
  {
    path: 'pos-detail',
    loadChildren: () => import('./pos-detail/pos-detail.module').then( m => m.PosDetailPageModule)
  },
  {
    path: 'pos-list',
    loadChildren: () => import('./pos-list/pos-list.module').then( m => m.PosListPageModule)
  },
  {
    path: 'laundry-list',
    loadChildren: () => import('./laundry-list/laundry-list.module').then( m => m.LaundryListPageModule)
  },
  {
    path: 'laundry-detail',
    loadChildren: () => import('./laundry-detail/laundry-detail.module').then( m => m.LaundryDetailPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'length-list',
    loadChildren: () => import('./length-list/length-list.module').then( m => m.LengthListPageModule)
  },
  {
    path: 'length-detail',
    loadChildren: () => import('./length-detail/length-detail.module').then( m => m.LengthDetailPageModule)
  },
  {
    path: 'fittin-list',
    loadChildren: () => import('./fittin-list/fittin-list.module').then( m => m.FittinListPageModule)
  },
  {
    path: 'fittin-detail',
    loadChildren: () => import('./fittin-detail/fittin-detail.module').then( m => m.FittinDetailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then( m => m.StaffPageModule)
  },
  {
    path: 'staff-detail',
    loadChildren: () => import('./staff-detail/staff-detail.module').then( m => m.StaffDetailPageModule)
  },
  {
    path: 'overall',
    loadChildren: () => import('./overall/overall.module').then( m => m.OverallPageModule)
  },
  {
    path: 'search-user',
    loadChildren: () => import('./search-user/search-user.module').then( m => m.SearchUserPageModule)
  },
  {
    path: 'search-product',
    loadChildren: () => import('./search-product/search-product.module').then( m => m.SearchProductPageModule)
  },
  {
    path: 'blogs',
    loadChildren: () => import('./blogs/blogs.module').then( m => m.BlogsPageModule)
  },
  {
    path: 'blog-detail',
    loadChildren: () => import('./blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule)
  },
  {
    path: 'photo-package-setting',
    loadChildren: () => import('./photo-package-setting/photo-package-setting.module').then( m => m.PhotoPackageSettingPageModule)
  },
  {
    path: 'appointment-setting',
    loadChildren: () => import('./appointment-setting/appointment-setting.module').then( m => m.AppointmentSettingPageModule)
  },
  {
    path: 'previous-order',
    loadChildren: () => import('./previous-order/previous-order.module').then( m => m.PreviousOrderPageModule)
  },
  {
    path: 'contact-us-list',
    loadChildren: () => import('./contact-us-list/contact-us-list.module').then( m => m.ContactUsListPageModule)
  },
  {
    path: 'contact-us-detail',
    loadChildren: () => import('./contact-us-detail/contact-us-detail.module').then( m => m.ContactUsDetailPageModule)
  },
  {
    path: 'photopackage-list',
    loadChildren: () => import('./photopackage-list/photopackage-list.module').then( m => m.PhotopackageListPageModule)
  },
  {
    path: 'photopackage-detail',
    loadChildren: () => import('./photopackage-detail/photopackage-detail.module').then( m => m.PhotopackageDetailPageModule)
  },
  {
    path: 'find-product',
    loadChildren: () => import('./find-product/find-product.module').then( m => m.FindProductPageModule)
  },
  {
    path: 'find-product',
    loadChildren: () => import('./find-product/find-product.module').then( m => m.FindProductPageModule)
  },
  {
    path: 'find-user',
    loadChildren: () => import('./find-user/find-user.module').then( m => m.FindUserPageModule)
  },
  {
    path: 'find-order',
    loadChildren: () => import('./find-order/find-order.module').then( m => m.FindOrderPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'charge-list',
    loadChildren: () => import('./charge-list/charge-list.module').then( m => m.ChargeListPageModule)
  },
  {
    path: 'charge-detail',
    loadChildren: () => import('./charge-detail/charge-detail.module').then( m => m.ChargeDetailPageModule)
  },
  {
    path: 'subscribs',
    loadChildren: () => import('./subscribs/subscribs.module').then( m => m.SubscribsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
